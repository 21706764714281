//ACTION TYPES
export const FETCH_LOADING = 'FETCH_LOADING';

export const GET_PROPOSAL_PARAMS = 'GET_PROPOSAL_PARAMS';
export const GET_PROPOSAL_LIST = 'GET_PROPOSAL_LIST';
export const GET_PROPOSAL_BY_ID = 'GET_PROPOSAL_BY_ID';
export const STORE_PROPOSAL = 'STORE_PROPOSAL';
export const CANCEL_PROPOSAL = 'CANCEL_PROPOSAL';

//MUTATION TYPES
export const SET_PROPOSAL_PARAMS = 'SET_PROPOSAL_PARAMS';
export const SET_PROPOSAL_LIST = 'SET_PROPOSAL_LIST';
export const SET_PROPOSAL_SHOW = 'SET_PROPOSAL_SHOW';

export const SET_PROPOSAL_SHOW_ASSET = 'SET_PROPOSAL_SHOW_ASSET';
