import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  proposal_list: [],
  proposal_pending: [],
  proposal_show: [],
  form_parameters: []
};

export const proposal = {
  name: 'proposal',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default proposal;
