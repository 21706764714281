import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  withdraw_list: [],
  withdraw_pending: false
};

export const withdraw = {
  name: 'withdraw',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default withdraw;
