import * as types from './mutation-types';
import ApiService from '@/core/services/api.service';

export default {
  [types.GET_BANK_ACCOUNT_PARAMS]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/profile/bank/params')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_BANK_ACCOUNT_FORM_PARAMS, data.form_params);
            resolve(data);
          }
        })
        .catch((error) => {
          // commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [types.GET_BANK_ACCOUNT_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/profile/bank')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_BANK_ACCOUNT_LIST, data.list_accounts);
            resolve(data);
          }
        })
        .catch((error) => {
          // commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [types.STORE_BANK_ACCOUNT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/profile/bank', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.DELETE_BANK_ACCOUNT](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete('/profile/bank/' + id)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
