import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import axios from 'axios';

export default {
  //LOGIN
  [types.LOGIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/login', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.commit(types.SET_USER, data);
            context.commit(types.SET_TOKEN, data.token);
            resolve(data);
          }
        })
        .catch((error) => {
          context.commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [types.VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get('/me').then(({ data }) => {
        context.commit(types.SET_USER, data);
      });
    } else {
      context.commit(types.PURGE_AUTH);
    }
  },
  [types.REFRESH_TOKEN](context) {
    return new Promise((resolve, reject) => {
      ApiService.patch('/refresh_token')
        .then((response) => {
          context.commit(types.SET_TOKEN, response.data.token);
          resolve(response);
        })
        .catch((error) => {
          context.commit(types.SET_ERROR, 'Sua sessão expirou!');
          reject(error);
        });
    });
  },
  [types.LOGOUT](context) {
    context.commit(types.PURGE_AUTH);
  },

  //REGISTER
  [types.REGISTER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/register', payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          context.commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [types.CHECK_IS_UNIQUE_CPF_CNPJ](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/register/check/code', payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CHECK_IS_UNIQUE_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/register/check/email', payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.REGISTER_VERIFY_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.REGISTER_RESEND_VERIFY_EMAIL](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('/email/resend')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
