import store from '@/core/services/store';

export default (to, from, next) => {
  if (!store.getters.currentUser.email_verified_at) {
    next({
      name: 'verify'
    });
    return false;
  }
};
