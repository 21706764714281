export default {
  invoiceGateways(state) {
    return state.gateways;
  },
  selectedInvoice(state) {
    return state.invoice;
  },
  selectedInvoiceTransaction(state) {
    return state.transaction;
  },
  selectedInvoiceReceipt(state) {
    return state.receipt;
  },
  depositIsPending(state) {
    return state.deposit_pending;
  }
};
