import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

const route = '/financial/real';

export default {
  [types.GET_EXTRACT_REAL]({ commit }, limit) {
    return new Promise((resolve, reject) => {
      ApiService.query(route + '/extract', { limit })
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_EXTRACT_REAL, data);
            resolve(data);
          }
          if (status === 403) {
            commit(types.SET_EXTRACT_REAL, null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CHECK_WALLET_TO_TRANSFER_REAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(route + '/transfer/check', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_TRANSFER_REAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(route + '/transfer', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
