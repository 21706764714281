import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  invoice: null,
  transaction: null,
  receipt: null,
  gateways: [],
  deposit_pending: false
};

export const invoice = {
  name: 'invoice',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default invoice;
