import * as types from './mutation-types';

export default {
  [types.ADD_BODY_CLASSNAME](context, className) {
    document.body.classList.add(className);
  },
  [types.REMOVE_BODY_CLASSNAME](context, className) {
    document.body.classList.remove(className);
  },
  [types.ADD_CLASSNAME](context, payload) {
    context.commit(types.SET_CLASSNAME_BY_POSITION, payload);
  }
};
