import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

export default {
  //WALLET SNAIL ACTIONS
  //extract actions
  [types.GET_EXTRACT_SNAILCOIN]({ commit }, limit) {
    return new Promise((resolve, reject) => {
      ApiService.query('/financial/snail/extract', { limit })
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_EXTRACT_SNAILCOIN, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CHECK_WALLET_TO_TRANSFER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/financial/snail/transfer/check', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_TRANSFER_SNAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/financial/snail/transfer', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_EMIT_SNAIL](context, value) {
    return new Promise((resolve, reject) => {
      ApiService.post('/financial/snail/emit', { value: value })
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_RECOMPOSE_SNAIL](context, value) {
    return new Promise((resolve, reject) => {
      ApiService.post('/financial/snail/recompose', { value: value })
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
