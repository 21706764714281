import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  classes: {}
};

export const htmlclass = {
  name: 'htmlclass',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default htmlclass;
