export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;

      let timerInterval;
      this.$swal
        .fire({
          icon: 'info',
          title: 'Estamos atualizando seu painel.',
          html: 'A pagina será atualizada em <b></b> segundos.',
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
            timerInterval = setInterval(() => {
              const content = this.$swal.getContent();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = (this.$swal.getTimerLeft() / 1000).toFixed(0);
                }
              }
            }, 100);
          },
          willClose: () => {
            this.refreshApp();
            clearInterval(timerInterval);
          }
        })
        .then(() => {
          this.refreshApp();
        });
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
};
