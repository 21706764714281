import * as types from './mutation-types';

export default {
  [types.SET_CLASSNAME_BY_POSITION](state, payload) {
    const { position, className } = payload;
    if (!state.classes[position]) {
      state.classes[position] = [];
    }
    state.classes[position].push(className);
  }
};
