import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

const route = '/financial/real';

export default {
  [types.GET_INVOICE_PARAMS]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get(route + '/invoice/params')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_INVOICE_GATEWAYS, data.gateways);
            commit(types.SET_DEPOSIT_PENDING, data.deposit_pending);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.PAY_INVOICE]({ commit }, invoice_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(route + '/invoice/pay/' + invoice_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_INVOICE_SELECTED, data.invoice);
            commit(types.SET_INVOICE_TRANSACTION, data.transaction);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.PAY_INVOICE_SELECT_METHOD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(route + '/invoice/pay/' + payload.invoice_id + '/' + payload.method_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_INVOICE_SELECTED, data.invoice);
            commit(types.SET_INVOICE_TRANSACTION, data.transaction);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CANCEL_INVOICE]({ commit }, invoice_id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(route + '/invoice/cancel/' + invoice_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_INVOICE_SELECTED, null);
            commit(types.SET_INVOICE_TRANSACTION, null);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_INVOICE_RECEIPT]({ commit }, invoice_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(route + '/invoice/receipt/' + invoice_id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_INVOICE_SELECTED_RECEIPT, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
