//**************************** ACTIONS TYPES ************************ */

//PERSONAL
export const UPDATE_PERSONAL_DATA = 'UPDATE_PERSONAL_DATA';

//ADDRESS
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

//CONTACT
export const INSERT_CONTACT = 'INSERT_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

//PASSWORD
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

//AVATAR
export const STORE_AVATAR = 'STORE_AVATAR';
export const DELETE_AVATAR = 'DELETE_AVATAR';

//DOCUMENTS
export const SEND_DOCUMENTS = 'SEND_DOCUMENTS';

export const SET_ERROR = 'SET_ERROR';
