import * as types from './mutation-types';

export default {
  [types.SET_PROPOSAL_PARAMS](state, payload) {
    state.form_parameters = payload.form_parameters;
    state.proposal_pending = payload.proposal_pending;
  },
  [types.SET_PROPOSAL_LIST](state, payload) {
    state.proposal_list = payload;
    state.isLoading = false;
  },
  [types.SET_PROPOSAL_SHOW](state, payload) {
    state.proposal_show = payload;
    state.isLoading = false;
  },
  [types.SET_PROPOSAL_SHOW_ASSET](state, payload) {
    state.proposal_show.asset = payload;
  },
  [types.FETCH_LOADING](state) {
    state.proposal_show = null;
    state.isLoading = true;
  }
};
