import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  breadcrumbs: []
};

export const breadcrumbs = {
  name: 'breadcrumbs',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default breadcrumbs;
