import store from '@/core/services/store';
import Vue from 'vue';

export default (to, from, next) => {
  if (store.getters.currentStatus.id == 2) {
    Vue.$toast.warning('Essa funcionalidade só será habilitada quando realizar a validação dos seus documentos.');
    next({ name: 'profile-documents' });
    return false;
  }
};
