import * as types from './mutation-types';

export default {
  [types.APPEND_BREADCRUM](state, payload) {
    state.breadcrumbs = [...state.breadcrumbs, payload];
  },
  [types.SET_BREADCRUMB](state, payload) {
    state.breadcrumbs = payload;
  }
};
