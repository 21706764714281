export default {
  currentToken(state) {
    return state.token;
  },
  currentUser(state) {
    return state.user;
  },
  currentCustomer(state) {
    return state.customer;
  },
  currentStatus(state) {
    return state.status;
  },
  currentAddress(state) {
    return state.address;
  },
  currentContacts(state) {
    return state.contacts;
  },
  currentWalletSnail(state) {
    if (!state.wallets) return null;
    if (!state.wallets.find((wallet) => wallet.type == '1'))
      return {
        id: null,
        balance: 0,
        limit: {
          value: 0,
          value_available: 0,
          value_in_use: 0
        }
      };
    return state.wallets.find((wallet) => wallet.type == '1');
  },
  currentWalletReal(state) {
    if (!state.wallets) return null;
    if (!state.wallets.find((wallet) => wallet.type == '2'))
      return {
        id: null,
        balance: 0,
        limit: {
          value: 0,
          value_available: 0,
          value_in_use: 0
        }
      };
    return state.wallets.find((wallet) => wallet.type == '2');
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};
