import * as types from './mutation-types';
import ApiService from '@/core/services/api.service';

export default {
  [types.GET_IMMOBILE_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/emission/immobile')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_IMMOBILE_LIST, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_IMMOBILE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/emission/immobile/' + payload.proposal_id, payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_NOTARY_OFFICE_BY_CITY_ID]({ commit }, state_id) {
    return new Promise((resolve, reject) => {
      ApiService.get('/emission/immobile/notary/' + state_id)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // [types.STORE_PROPERTY](state, payload) {},
  // [types.GET_PROPERTY_BY_PROPOSAL_ID](state, payload) {}
};
