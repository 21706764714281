//TOKEN
export const getToken = () => {
  return window.localStorage.getItem('token');
};

export const saveToken = (token) => {
  window.localStorage.setItem('token', token);
};

export const destroyToken = () => {
  window.localStorage.removeItem('token');
};

export default {
  getToken,
  saveToken,
  destroyToken
};
