import ApiService from '@/core/services/api.service';
import * as types from './mutation-types';

export default {
  //PERSONAL DATA
  [types.UPDATE_PERSONAL_DATA](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put('/profile/personaldata', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.dispatch('VERIFY_AUTH');
          context.commit(types.SET_ERROR, response.data.error);
          reject();
        });
    });
  },

  //ADDRESS
  [types.UPDATE_ADDRESS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put('/profile/address', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          context.commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },

  //CONTACT
  [types.INSERT_CONTACT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/profile/contact', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.dispatch('VERIFY_AUTH');
          context.commit(types.SET_ERROR, response.data.error);
          reject();
        });
    });
  },
  [types.DELETE_CONTACT](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(`/profile/contact/${id}`)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.dispatch('VERIFY_AUTH');
          context.commit(types.SET_ERROR, response.data.error);
          reject();
        });
    });
  },

  //PASSWORD
  [types.UPDATE_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put('/profile/security', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          context.commit(types.SET_ERROR, error);
          reject(error);
        });
    });
  },

  //DOCUMENTS
  [types.SEND_DOCUMENTS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/profile/document', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.dispatch('VERIFY_AUTH');
          context.commit(types.SET_ERROR, response.data.error);
          reject();
        });
    });
  },

  //AVATAR
  [types.STORE_AVATAR](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/profile/avatar', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.DELETE_AVATAR](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete('/profile/avatar')
        .then(({ data, status }) => {
          if (status === 200) {
            context.dispatch('VERIFY_AUTH');
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
