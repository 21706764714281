import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

export default {
  //PROPOSAL
  [types.GET_PROPOSAL_PARAMS]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/emission/proposal/params')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_PROPOSAL_PARAMS, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_PROPOSAL_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/emission/proposal')
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_PROPOSAL_LIST, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_PROPOSAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/emission/proposal', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_PROPOSAL_BY_ID]({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.get('/emission/proposal/show/' + id)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_PROPOSAL_SHOW, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CANCEL_PROPOSAL](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/emission/proposal/cancel/' + id)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
