import store from '@/core/services/store';

export default (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    store.dispatch('LOGOUT');
    next({
      name: 'login'
    });
    return false;
  } else {
    store.dispatch('VERIFY_AUTH');
    store.dispatch('RESET_LAYOUT_CONFIG');
    next();
  }
};
