import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

//ENCRYPT STATE
class CustomSecureLS extends SecureLS {
  init() {
    this.utils.metaKey = 'encryption';
    super.init();
  }
}
var ls = new CustomSecureLS({
  encodingType: 'rc4',
  isCompression: false,
  encryptionSecret: 's3cr3tPa$$w0rd@123'
});
const authState = createPersistedState({
  key: 'user_data',
  paths: ['auth'],
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key)
  }
});

// Import all of the resource store files.
function loadStores() {
  const context = require.context('./modules', true, /index.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}

const resourceModules = {};
loadStores().forEach((resource) => {
  resourceModules[resource.name] = resource;
});

//init vuex modules
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [authState],
  modules: resourceModules
});
