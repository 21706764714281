import * as types from './mutation-types';
import merge from 'deepmerge';

export default {
  [types.SET_LAYOUT_CONFIG](state, payload) {
    state.config = payload;
  },
  [types.RESET_LAYOUT_CONFIG](state) {
    state.config = Object.assign({}, state.initial);
  },
  [types.OVERRIDE_LAYOUT_CONFIG](state) {
    state.config = state.initial = Object.assign({}, state.initial, JSON.parse(localStorage.getItem('config')));
  },
  [types.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
    state.config = merge(state.config, payload);
  }
};
