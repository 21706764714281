import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import vuetify from '@/core/plugins/vuetify';
import i18n from '@/core/plugins/vue-i18n';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';

// Global 3rd party plugins
import '@/core/plugins/apexcharts';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/portal-vue';

// Vue 3rd party plugins
import '@mdi/font/css/materialdesignicons.css';
import PerfectScrollbar from 'perfect-scrollbar';
import 'popper.js';
import 'tooltip.js';

//Plugins instalados
import './core/plugins/customPlugins';

//Filters
import './core/filters/generalFilters';

//Mixins
import './core/mixins/generalMixin';

//Mixins
import './core/mixins/generalMixin';

//Websocket
// import '@/core/services/websocket.service';

Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;

// API service init
ApiService.init();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
