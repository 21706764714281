import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  deposit_list: [],
  new_deposit: {}
};

export const deposit = {
  name: 'deposit',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default deposit;
