import * as invoiceTypes from '../invoice/mutation-types';
import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

export default {
  [types.GET_DEPOSIT_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.query('/financial/real/deposit', params)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_DEPOSIT_LIST, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_DEPOSIT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/financial/real/deposit', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            commit(invoiceTypes.SET_INVOICE_SELECTED, data.invoice);
            commit(invoiceTypes.SET_INVOICE_TRANSACTION, data.transaction);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
