import JwtService from '@/core/services/jwt.service';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const getDefaultState = () => {
  return {
    errors: {},
    user: null,
    customer: null,
    address: null,
    contacts: null,
    wallets: null,
    status: null,
    token: null,
    isAuthenticated: false
  };
};

const state = getDefaultState();

export const auth = {
  name: 'auth',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default auth;
