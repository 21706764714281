import JwtService from '@/core/services/jwt.service';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';

/**
 * Service to call HTTP request via Axios
 */

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    Vue.axios.defaults.timeout = 30000;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(token = JwtService.getToken()) {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  query(resource, params) {
    return Vue.axios
      .get(resource, {
        params
      })
      .catch((error) => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    if (slug) {
      slug = '/' + slug;
    }
    return Vue.axios.get(`${resource}${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    if (slug) {
      slug = '/' + slug;
    }
    return Vue.axios.put(`${resource}${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource) {
    return Vue.axios.patch(resource);
  }
};

export default ApiService;
