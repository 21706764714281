// action types
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY_AUTH = 'VERIFY_AUTH';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const REGISTER = 'REGISTER';
export const REGISTER_VERIFY_EMAIL = 'REGISTER_VERIFY_EMAIL';
export const REGISTER_RESEND_VERIFY_EMAIL = 'REGISTER_RESEND_VERIFY_EMAIL';
export const CHECK_IS_UNIQUE_EMAIL = 'CHECK_IS_UNIQUE_EMAIL';
export const CHECK_IS_UNIQUE_CPF_CNPJ = 'CHECK_IS_UNIQUE_CPF_CNPJ';

// mutation types
export const PURGE_AUTH = 'PURGE_AUTH';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
