import Vue from 'vue';

Vue.mixin({
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    hasActiveParents(parent) {
      return this.$route['path'].includes(parent);
    },
    // for sub routes
    subIsActive(path) {
      return this.$route.path === path;
    },
    // for main routes
    IsActive(path) {
      return this.$route.path.startsWith(path);
    },
    nameLastname(fullname) {
      var nome = fullname.split(' ')[0];
      var qtdnome = fullname.split(' ').length;
      var sobrenome = fullname.split(' ')[qtdnome - 1];

      return `${nome} ${sobrenome}`;
    },

    maskMoneyReal() {
      return {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      };
    },
    maskMoneySnail() {
      return {
        decimal: ',',
        thousands: '.',
        prefix: 'SN$ ',
        suffix: '',
        precision: 2,
        masked: false
      };
    },
    formatDateBrl(date) {
      return this.$moment(date).format('DD/MM/YYYY HH:MM:SS');
    },
    formatDateHourToHour(date) {
      return this.$moment(date).format('HH:MM:SS');
    },
    notify() {
      this.$swal({
        text: 'Aguarde enquanto cancelamos o depósito...',
        icon: 'warning',
        showConfirmButton: false,
        dangerMode: true,
        allowOutsideClick: false
      });
    }
  }
});
