export default {
  proposal_list(state) {
    return state.proposal_list;
  },
  proposal_show(state) {
    return state.proposal_show;
  },
  form_parameters(state) {
    return state.form_parameters;
  },
  isLoading(state) {
    return state.isLoading;
  }
};
