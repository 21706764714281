import * as types from './mutation-types';
export default {
  /**   * Set the breadcrumbs list   * @param state   * @param payload   */
  [types.SET_BREADCRUMB](state, payload) {
    state.commit(types.SET_BREADCRUMB, payload);
  },

  /**   * Add breadcrumb   * @param state   * @param payload   */
  [types.ADD_BREADCRUMB](state, payload) {
    if (typeof payload === 'object') {
      payload.forEach((item) => state.commit(types.APPEND_BREADCRUM, item));
    } else {
      state.commit(types.APPEND_BREADCRUM, payload);
    }
  }
};
