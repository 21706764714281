export default {
  // Get list of breadcrumbs for current page
  breadcrumbs(state) {
    return state.breadcrumbs;
  },

  // Get the page title
  pageTitle(state) {
    let last = state.breadcrumbs[state.breadcrumbs.length - 1];
    if (last && last.title) {
      return last.title;
    }
  }
};
