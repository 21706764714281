import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  immobile_list: []
};

export const immobile = {
  name: 'immobile',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default immobile;
