import * as types from './mutation-types';

export default {
  [types.SET_BANK_ACCOUNT_FORM_PARAMS](state, payload) {
    state.form_params = payload;
  },
  [types.SET_BANK_ACCOUNT_LIST](state, payload) {
    state.list_accounts = payload;
  }
};
