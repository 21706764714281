import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import { VueMaskFilter } from 'v-mask';
import VueTheMask from 'vue-the-mask';

//VueTheMask
Vue.use(VueTheMask);

//VueMask
Vue.filter('VMask', VueMaskFilter);

//Vue2Filters
const Vue2FiltersConfig = {
  currency: {
    symbol: 'R$ ',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  },
  currency_snail: {
    symbol: 'SN$ ',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  }
};

Vue.use(Vue2Filters, Vue2FiltersConfig);
