import * as types from './mutation-types';

export default {
  [types.SET_WITHDRAW_LIST](state, payload) {
    state.withdraw_list = payload;
  },
  [types.SET_WITHDRAW_IS_PENDING](state, payload) {
    state.withdraw_pending = payload;
  }
};
