//ACTION TYPES
export const GET_EXTRACT_SNAILCOIN = 'GET_EXTRACT_SNAILCOIN';

export const CHECK_WALLET_TO_TRANSFER = 'CHECK_WALLET_TO_TRANSFER';
export const STORE_TRANSFER_SNAIL = 'STORE_TRANSFER_SNAIL';
export const STORE_EMIT_SNAIL = 'STORE_EMIT_SNAIL';
export const STORE_RECOMPOSE_SNAIL = 'STORE_RECOMPOSE_SNAIL';

//MUTATION TYPES
export const SET_EXTRACT_SNAILCOIN = 'SET_EXTRACT_SNAILCOIN';
