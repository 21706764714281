/* eslint-disable */

import Router from 'vue-router';
import Vue from 'vue';
import axios from 'axios';
import store from '@/core/services/store';

import AuthMiddleware from './middleware/auth';
import GuestMiddleware from './middleware/guest';
import VerifyDocumentsMiddleware from './middleware/verifyDocuments';
import VerifyEmailMiddleware from './middleware/verifyEmail';

Vue.use(Router);

import VueRouteMiddleware from 'vue-route-middleware';

const router = new Router({
  mode: 'history',
  routes: [
    //Auth Routes
    {
      path: '/login',
      component: () => import('@/screens/Auth/Auth'),
      children: [
        //Login
        {
          name: 'login',
          path: '/login',
          component: () => import('@/screens/Auth/components/Login'),
          meta: {
            middleware: [GuestMiddleware]
          }
        },
        //Register
        {
          name: 'register',
          path: '/register',
          component: () => import('@/screens/Auth/components/Register')
        }
      ]
    },
    //Verify Email
    {
      path: '/verify',
      component: () => import('@/screens/Auth/Auth'),
      children: [
        {
          name: 'verify',
          path: '/verify',
          component: () => import('@/screens/Auth/components/EmailVerifyPending'),
          meta: {
            middleware: [
              AuthMiddleware,
              (to, from, next) => {
                if (store.getters.currentUser.email_verified_at) {
                  next({ name: 'dashboard' });
                  return false; // if false is returned, middleware chain will break!
                }
              }
            ]
          }
        },
        {
          name: 'verify-check',
          path: '/verify/check',
          component: () => import('@/screens/Auth/components/EmailVerifyCheck')
        }
      ]
    },
    //Dashboard Routes
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/shared/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/screens/Dashboard/Dashboard.vue')
        },
        //Profile
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/screens/Profile/Profile.vue'),
          children: [
            {
              path: '/profile/personal',
              name: 'profile-personal',
              component: () => import('@/screens/Profile/components/PersonalData.vue')
            },
            {
              path: '/profile/address',
              name: 'profile-address',
              component: () => import('@/screens/Profile/components/AddressData.vue')
            },
            {
              path: '/profile/contact',
              name: 'profile-contact',
              component: () => import('@/screens/Profile/components/ContactData.vue')
            },
            {
              path: '/profile/bank',
              name: 'profile-bank',
              component: () => import('@/screens/Profile/components/BankData/ListAccounts.vue')
            },
            {
              path: '/profile/security',
              name: 'profile-security',
              component: () => import('@/screens/Profile/components/SecurityData.vue')
            },
            {
              path: '/profile/documents',
              name: 'profile-documents',
              component: () => import('@/screens/Profile/components/DocumentValidation.vue')
            }
          ],
          redirect: { name: 'profile-personal' }
        },
        //Financial
        {
          path: '/financial',
          name: 'financial',
          component: () => import('@/screens/Financial/Financial.vue'),
          children: [
            {
              path: '/financial/snail',
              component: () => import('@/screens/Financial/components/Snail/Snail.vue'),
              redirect: { name: 'wallet-snail-extract' },
              children: [
                {
                  path: '/financial/snail/extract',
                  name: 'wallet-snail-extract',
                  component: () => import('@/screens/Financial/components/Snail/Extract.vue')
                },
                {
                  path: '/financial/snail/transfer',
                  name: 'wallet-snail-transfer',
                  component: () => import('@/screens/Financial/components/Snail/Transfer.vue')
                }
              ]
            },
            {
              path: '/financial/real',
              component: () => import('@/screens/Financial/components/Real/Real.vue'),
              redirect: { name: 'wallet-real-extract' },
              children: [
                {
                  path: '/financial/real/extract',
                  name: 'wallet-real-extract',
                  component: () => import('@/screens/Financial/components/Real/Extract/Extract.vue')
                },
                {
                  path: '/financial/real/transfer',
                  name: 'wallet-real-transfer',
                  component: () => import('@/screens/Financial/components/Real/Transfer/Transfer.vue')
                },
                {
                  path: '/financial/real/deposit',
                  name: 'wallet-real-deposit',
                  component: () => import('@/screens/Financial/components/Real/Deposit/ListDeposits.vue')
                },
                {
                  path: '/financial/real/withdraw',
                  name: 'wallet-real-withdraw',
                  component: () => import('@/screens/Financial/components/Real/Withdraw/ListWithdraws.vue')
                }
              ]
            }
          ],
          redirect: { name: 'wallet-snail-extract' },
          meta: {
            middleware: [VerifyDocumentsMiddleware]
          }
        },
        //Emission
        {
          path: '/emission',
          name: 'emission',
          component: () => import('@/screens/Emission/Emission.vue'),
          children: [
            {
              path: '/emission/proposals',
              name: 'proposal-list',
              component: () => import('@/screens/Emission/Proposals/components/ListProposals.vue')
            },
            {
              path: '/emission/proposals/show/:id',
              name: 'proposal-show',
              component: () => import('@/screens/Emission/Proposals/components/ShowProposal.vue')
            }
          ],
          redirect: { name: 'proposal-list' },
          meta: {
            middleware: [VerifyDocumentsMiddleware]
          }
        }
      ],
      meta: {
        middleware: [AuthMiddleware, VerifyEmailMiddleware]
      }
    },
    //Error Routes
    {
      path: '/error',
      name: 'error',
      component: () => import('@/screens/Error/Error.vue'),
      children: [
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/screens/Error/Error-6.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/screens/Error/Error-1.vue')
    }
  ]
});

/******** MIDDLEWARES ********/
router.beforeEach(VueRouteMiddleware());

/******** INTERCEPTORS RESPONSE *********/
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter((callback) => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      Vue.$toast.error('Nosso sistema se encontra fora do ar no momento.');
      return Promise.reject('Nosso sistema se encontra fora do ar no momento.');
    }

    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;

    switch (status) {
      case 400:
        Vue.$toast.error(error.response.data.error);
        return Promise.reject(error.response.data.error);
      case 401:
        if (!store.getters.isAuthenticated || !localStorage.getItem('token')) {
          store.dispatch('LOGOUT');
          return Promise.reject(error.response.data.error);
        } else {
          if (!isAlreadyFetchingAccessToken && error.response.data.error == 'Token has expired') {
            isAlreadyFetchingAccessToken = true;
            // instead of this store call you would put your code to get new token
            store.dispatch('REFRESH_TOKEN').then((response) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(response.data.token);
            });
          } else if (error.response.data.error == 'The token has been blacklisted') {
            store.dispatch('LOGOUT');
            router.push({ name: 'login' });
          }
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      case 503:
        Vue.$toast.error('Nosso sistema se encontra em manutenção!');
        store.dispatch('LOGOUT');
        router.push({ name: 'login' });
        return Promise.reject(error.response.data.error);
      default:
        return Promise.reject('Houve um erro interno do nosso lado, tente novamente mais tarde.');
    }
  }
);

export default router;
