import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  walletReal: {
    events: []
  }
};

export const walletReal = {
  name: 'wallet-real',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default walletReal;
