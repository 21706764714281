import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  form_params: {
    account_types: [],
    banks: []
  },
  list_accounts: []
};

export const profileBank = {
  name: 'profile-bank',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default profileBank;
