import * as types from './mutation-types';
import ApiService from '@/core/services/api.service';

const route = '/financial/real';

export default {
  [types.GET_WITHDRAW_PARAMS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(route + '/withdraw/params', params)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_WITHDRAW_IS_PENDING, data.withdraw_pending);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_WITHDRAW_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.query(route + '/withdraw', params)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_WITHDRAW_LIST, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_WITHDRAW]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(route + '/withdraw', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            commit(types.SET_WITHDRAW_IS_PENDING, true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CANCEL_WITHDRAW]({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(route + '/withdraw/' + id)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            commit(types.SET_WITHDRAW_IS_PENDING, false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
