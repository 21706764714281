import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  errors: {},
  documents: []
};

export const profile = {
  name: 'profile',
  state,
  actions,
  mutations,
  getters
};

export * from './mutation-types';

export default profile;
