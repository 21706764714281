import * as types from './mutation-types';

export default {
  [types.SET_LAYOUT_CONFIG](state, payload) {
    state.commit(types.SET_LAYOUT_CONFIG, payload);
  },

  [types.RESET_LAYOUT_CONFIG](state) {
    state.commit(types.RESET_LAYOUT_CONFIG);
  },

  [types.OVERRIDE_LAYOUT_CONFIG](state) {
    state.commit(types.OVERRIDE_LAYOUT_CONFIG);
  },

  [types.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
    state.commit(types.OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
  }
};
