import * as types from './mutation-types';
import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import { getDefaultState } from './index.js';

export default {
  [types.SET_ERROR](state, error) {
    state.errors = error;
  },
  [types.SET_USER](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.customer = data.customer;
    state.address = data.address;
    state.contacts = data.contacts;
    state.wallets = data.wallets;
    state.status = data.status;
    state.errors = {};
  },
  [types.SET_TOKEN](state, token) {
    ApiService.setHeader(token);
    state.token = token;
    JwtService.saveToken(token);
  },
  [types.PURGE_AUTH](state) {
    Object.assign(state, getDefaultState());
    JwtService.destroyToken();
  },
  [types.RESET_ERROR](state) {
    state.errors = {};
  }
};
