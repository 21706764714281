import * as types from './mutation-types';

export default {
  [types.SET_DEPOSIT_IS_PENDING](state, payload) {
    state.deposit_pending = payload;
  },
  [types.SET_DEPOSIT_LIST](state, payload) {
    state.deposit_list = payload;
  },
  [types.SET_NEW_DEPOSIT](state, payload) {
    state.new_deposit = payload;
  }
};
