import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import config from '@/core/config/layout.config.json';
const state = {
  config: config,
  initial: config
};

export const configs = {
  name: 'configs',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default configs;
