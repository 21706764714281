//ACTION TYPES
export const PAY_INVOICE = 'PAY_INVOICE';
export const PAY_INVOICE_SELECT_METHOD = 'PAY_INVOICE_SELECT_METHOD';
export const CANCEL_INVOICE = 'CANCEL_INVOICE';
export const GET_INVOICE_RECEIPT = 'GET_INVOICE_RECEIPT';
export const GET_INVOICE_PARAMS = 'GET_INVOICE_PARAMS';

//MUTATION TYPES
export const SET_INVOICE_SELECTED_RECEIPT = 'SET_INVOICE_SELECTED_RECEIPT';
export const SET_INVOICE_SELECTED = 'SET_INVOICE_SELECTED';
export const SET_INVOICE_TRANSACTION = 'SET_INVOICE_TRANSACTION';
export const SET_INVOICE_GATEWAYS = 'SET_INVOICE_GATEWAYS';
export const SET_DEPOSIT_PENDING = 'SET_DEPOSIT_PENDING';
