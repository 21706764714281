import * as types from './mutation-types';

export default {
  [types.SET_INVOICE_SELECTED_RECEIPT](state, payload) {
    state.receipt = payload;
  },
  [types.SET_INVOICE_SELECTED](state, payload) {
    state.invoice = payload;
  },
  [types.SET_INVOICE_TRANSACTION](state, payload) {
    state.transaction = payload;
  },
  [types.SET_INVOICE_GATEWAYS](state, payload) {
    state.gateways = payload;
  },
  [types.SET_DEPOSIT_PENDING](state, payload) {
    state.deposit_pending = payload;
  }
};
